<template>
  <v-form>
    <v-card>
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-toolbar-title>Dealers</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="flatClose"
          icon
          to="/business-partners/dealers"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- end of toolbar 1 -->

      <!-- start of toolbar 2 -->
      <v-toolbar
        color="secondary"
        flat
        dense
      >
        <v-btn
          class="mr-1"
          outlined
          color="toolbarIcon"
          text
          @click="sendData"
          :loading="loader"
        >
          <v-icon
            left
            dark
          >mdi-content-save</v-icon>Submit
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-1"
          outlined
          color="toolbarIcon"
          text
          to="/business-partners/dealers"
        >
          <v-icon
            left
            dark
          >mdi-eye</v-icon>All Dealers
        </v-btn>
      </v-toolbar>
      <!-- end of toolbar 2 -->
      <v-card-text>
        <v-row dense>
          <v-col cols="4">
            <v-text-field
              outlined
              dense
              label="Dealer Name"
              v-model="record.Name"
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              outlined
              dense
              label="Phone Number"
              v-model="record.Phone"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-autocomplete
              dense
              outlined
              v-model="record.BPLId"
              label="Branches"
              :items="branches"
              item-text="BPLName"
              item-value="id"
            ></v-autocomplete>
          </v-col>

          <v-col cols="4">
            <v-text-field
              outlined
              dense
              label="Discount %"
              v-model="record.Discount"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              outlined
              dense
              label="Dealer Type"
              v-model="record.Type"
            ></v-text-field>
          </v-col>
          <!-- col md 3 -->

          <v-col cols="4">
            <v-text-field
              outlined
              dense
              label="Location Abbreviation"
              v-model="record.LocationAbrn"
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              outlined
              dense
              label="Town"
              v-model="record.Town"
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              outlined
              dense
              label="County"
              v-model="record.County"
            ></v-text-field>
          </v-col>

          <!-- end -->
        </v-row>

        <!-- start of account sellection modal -->
        <v-dialog
          v-model="accountDialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <v-card>
            <v-toolbar
              dark
              color="accent"
            >
              <v-toolbar-title>
                <v-btn
                  icon
                  dark
                  @click="accountDialog = false"
                >
                  <v-icon dark>mdi-keyboard-backspace</v-icon>
                </v-btn>Select Account
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                icon
                dark
                @click="accountDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-card-title>
                      <v-text-field
                        hide-details
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                      ></v-text-field>
                    </v-card-title>
                    <!--  <v-data-table
                      @item-selected="clicked"
                      :items-per-page="itemsPerPage"
                      v-model="selected"
                      :headers="headers"
                      :items="accounts"
                      :single-select="singleSelect"
                      item-key="AcctCode"
                      show-select
                      class="elevation-1"
                      :search="search"
                    ></v-data-table> -->
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- end of account selection modal -->

        <!-- start of territory dialog -->
        <v-dialog
          v-model="territoryDialog"
          width="650px"
        >
          <v-card>
            <v-toolbar
              color="accent"
              dark
              dense
            >
              <v-toolbar-title>Select Territory</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                icon
                @click.native="territoryDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <!-- territories -->
                <!--  <v-col cols="12">
                  <v-treeview
                    color="accent"
                    :activatable="trueValue"
                    :hoverable="trueValue"
                    :selection-type="selectionType"
                    open-all
                    return-object
                    item-text="descript"
                    item-key="id"
                    :items="territories"
                    item-children="children_recursive"
                    :search="search"
                  >
                    <template v-slot:label="{ item }">
                      <div @click="selectItem(item)">
                        <span style="font-weight: bold">{{item.descript}}</span>
                      </div>
                    </template>
                  </v-treeview>
                </v-col> -->
                <!-- end -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- snackbar -->
        <snackbar ref="snackbar"></snackbar>
        <!-- end of territory dialog -->
      </v-card-text>
    </v-card>
  </v-form>
</template>


<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
    itemsData: {
      type: Object,
    },
    pageMode: {
      type: String,
    },
  },
  data: () => ({
    center: { lat: -1.219719, lng: 36.889224 },
    Series: null,
    loader: false,
    manualNum: false,
    branches: [],
    CardType: null,
    markers: [],
    places: [],
    currentPlace: null,
    record: {},
    territory: {},
    territoryDialog: false,
    selectionType: "leaf",
    search: null,
    trueValue: true,
    falseValue: false,
    itemsPerPage: 20,
    selected: [],
    singleSelect: true,
    DebPayAcct: null,
    DpmClear: null,
    DpmIntAct: null,
    tab: null,
    accountDialog: false,
    accountField: null,
    appendedIcon: "mdi-dots-horizontal-circle-outline",
    accounts: [],
    priceList: [],
    bpProperties: [],
    employees: [],
    territories: [],
    channels: [],
    checkbox: null,
    tiers: [],
    series: [{ name: "Manual", value: "M" }],
    bpGroups: [],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
    itemsData: {
      handler: "setBpMaster",
      immediate: true,
    },
  },
  methods: {
    getBranches() {
      this.$store
        .dispatch("get", `/branches`)
        .then((res) => {
          this.branches = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },

    selectItem(data) {
      this.territory = data;
      this.territoryDialog = false;
    },
    init(val) {
      this.record = { ...val };
    },
    setBpMaster(val) {
      this.record = { ...val };
    },
    setDate(date) {
      this.record.date = date;
    },

    sendData() {
      if (this.record.Name == undefined) {
        this.$refs.snackbar.show("Please fill Dealer Name", "red");
      }
      this.loader = true;
      this.$emit("data", this.record);
    },
  },
  created() {
    this.getBranches();
  },
};
</script>